
import {
    CloudUploadOutlined,
    MinusCircleOutlined
} from "@ant-design/icons-vue"
export default {
    components: {
        CloudUploadOutlined,
        MinusCircleOutlined
    },
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 表单数据
            form: Object.assign({
                trackingNumber: [{
                    tracking_number: ''
                }],
                ticket_file: []
            }, this.data),
            // 表单验证规则
            rules: {
                ticket_type_id: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                ticket_title: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                ticket_content: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 工单类型
            ticket_types: [],
            // 选择工单类型
            current_type: {},
            // 附件列表
            fileList: []
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
            } else {
                this.form = {};
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    computed: {
        countPrice() {
            const type = this.current_type.charge_type;
            let price = 0;

            if (type == 1) {
                price = this.current_type.charge_value || 0
            } else if (type == 2) {
                price = this.current_type.charge_value * (this.form.trackingNumber ? this.form.trackingNumber.length : 0)
            } else if (type == 3) {
                price = 0
            } else {
                price = 0
            }

            return `${price.toFixed(2)}  ${this.current_type.charge_currency || ''}`
        }
    },
    methods: {
        /**
         * 保存数据
         */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post('/user/ticket/update', this.form).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.form = {};
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        onClose() {
            this.updateVisible(false);
        },
        // 上传附件
        handleUpload(resFile) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", resFile.file);

            this.$http
                .post("/user/ticket/upload_attachment", formData)
                .then((response) => {
                    if (response.status == 200 && response.data.code == 0) {
                        const res = response.data;
                        this.form.ticket_file.push({
                            file_path: res.data,
                            file_size: resFile.file.size,
                            file_name: resFile.file.name
                        })

                        this.$message.success(this.$t("操作成功"));
                        resFile.onSuccess(res.data, resFile.file);
                    }
                })
                .catch(() => {
                    resFile.onError();
                    this.$message.error(this.$t("操作失败"));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 获取工单类型
        onLoadTicketTypes() {
            this.$http.get("/admin/ticket/ticket_type").then(res => {
                if (res.data.code == 0) {
                    this.ticket_types = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch((e) => {
                this.$message.error(e)
            })
        },
        // 重置追踪号
        resetTrack() {
            this.form.trackingNumber.forEach((item) => item.tracking_number = '')
        },
        // 选择工单类型
        changeType(v) {
            const obj = this.ticket_types.find(item => item.id == v);
            this.form.ticket_type_title = obj.type_title;
            this.current_type = obj;
        }
    },
    created() {
        this.onLoadTicketTypes();
    }
}
